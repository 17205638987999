var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c("h1", [_vm._v("Previred Widget Test App")]),
    _c("div", { staticClass: "d-flex w-100" }, [
      _c("div", { staticClass: "form d-m-block" }, [
        _c("div", { staticClass: "config-caontainer" }, [
          _c("div", { staticClass: "row title" }, [
            _vm._v("Párametros comunes"),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _vm._m(0),
              _c("input", {
                staticClass: "input",
                attrs: {
                  id: "rut",
                  type: "text",
                  placeholder: "Ingrese su rut",
                },
                domProps: { value: _vm.rut },
                on: {
                  input: function ($event) {
                    _vm.rut = $event.target.value.toUpperCase()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col" }, [
              _c("label", { staticClass: "d-block", attrs: { for: "name" } }, [
                _vm._v("Rut ejecutivo"),
              ]),
              _c("input", {
                staticClass: "input",
                attrs: {
                  id: "executiveRut",
                  type: "text",
                  placeholder: "Ingrese rut ejecutivo",
                },
                domProps: { value: _vm.executiveRut },
                on: {
                  input: function ($event) {
                    _vm.executiveRut = $event.target.value.toUpperCase()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                { staticClass: "d-block", attrs: { for: "authMethod" } },
                [_vm._v("Método de autenticación")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.authMethod,
                      expression: "authMethod",
                    },
                  ],
                  staticClass: "w-100 input",
                  attrs: { id: "authMethod" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.authMethod = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "ACEPTA" } }, [
                    _vm._v("ACEPTA - Validación de huella"),
                  ]),
                  _c("option", { attrs: { value: "EQUIFAX" } }, [
                    _vm._v("EQUIFAX - Preguntas y Respuestas"),
                  ]),
                  _c("option", { attrs: { value: "SINACOFI" } }, [
                    _vm._v("SINACOFI - Preguntas y Respuestas"),
                  ]),
                  _c("option", { attrs: { value: "REMOTE_ASSISTED" } }, [
                    _vm._v("HIBRIDO - Remoto Asistido"),
                  ]),
                  _c("option", { attrs: { value: "REMOTE_ASSISTED_FACIAL" } }, [
                    _vm._v("HIBRIDO - Remoto Facial"),
                  ]),
                  _c("option", { attrs: { value: "CICUE" } }, [
                    _vm._v("HIBRIDO - Remoto CICUE"),
                  ]),
                  _c("option", { attrs: { value: "FACIAL_RECOGNITION" } }, [
                    _vm._v("TOC - Reconocimiento Facial"),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                { staticClass: "d-block", attrs: { for: "timeLimitMode" } },
                [_vm._v("Limite de tiempo Desafío")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.timeLimitMode,
                      expression: "timeLimitMode",
                    },
                  ],
                  staticClass: "w-100 input",
                  attrs: { id: "timeLimitMode" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.timeLimitMode = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "PER_ANSWER" } }, [
                    _vm._v("Por pregunta"),
                  ]),
                  _c("option", { attrs: { value: "PER_QUIZ" } }, [
                    _vm._v("Por quiz"),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                { staticClass: "d-block", attrs: { for: "method" } },
                [_vm._v("Modo Kong")]
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.kongMethod,
                      expression: "kongMethod",
                    },
                  ],
                  staticClass: "w-100 input",
                  attrs: { id: "kongMethod" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kongMethod = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c(
                    "option",
                    {
                      attrs: { selected: "" },
                      domProps: { value: _vm.kongMethods.CLIENT_CREDENTIALS },
                    },
                    [_vm._v("Client Credentials")]
                  ),
                  _c("option", { domProps: { value: _vm.kongMethods.TOKEN } }, [
                    _vm._v("Bearer Token"),
                  ]),
                ]
              ),
            ]),
            _vm.kongMethod === _vm.kongMethods.TOKEN
              ? _c("div", { staticClass: "col" }, [
                  _vm._m(1),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.kongToken,
                        expression: "kongToken",
                      },
                    ],
                    staticClass: "input",
                    attrs: {
                      id: "kongToken",
                      type: "text",
                      placeholder: "Ingrese su Kong Token",
                    },
                    domProps: { value: _vm.kongToken },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.kongToken = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.kongMethod === _vm.kongMethods.CLIENT_CREDENTIALS
              ? _c("div", { staticClass: "col" }, [
                  _vm._m(2),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.kongClientIdField,
                        expression: "kongClientIdField",
                      },
                    ],
                    staticClass: "input",
                    attrs: {
                      id: "kongClientIdField",
                      type: "text",
                      placeholder: "Ingrese su Kong Client Id",
                    },
                    domProps: { value: _vm.kongClientIdField },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.kongClientIdField = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.kongMethod === _vm.kongMethods.CLIENT_CREDENTIALS
              ? _c("div", { staticClass: "col" }, [
                  _vm._m(3),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.kongClientSecretField,
                        expression: "kongClientSecretField",
                      },
                    ],
                    staticClass: "input",
                    attrs: {
                      id: "kongClientSecretField",
                      type: "text",
                      placeholder: "Ingrese Kong Secret",
                    },
                    domProps: { value: _vm.kongClientSecretField },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.kongClientSecretField = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _vm._v(" Modo dummy "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.dummyMode,
                    expression: "dummyMode",
                  },
                ],
                attrs: { id: "dummyMode", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.dummyMode)
                    ? _vm._i(_vm.dummyMode, null) > -1
                    : _vm.dummyMode,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.dummyMode,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.dummyMode = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.dummyMode = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.dummyMode = $$c
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col" }, [
              _vm._m(4),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.environment,
                      expression: "environment",
                    },
                  ],
                  staticClass: "w-100 input",
                  attrs: { id: "environment" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.environment = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.allowedEnvironments, function (env) {
                  return _c("option", { key: env, domProps: { value: env } }, [
                    _vm._v(_vm._s(env)),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                { staticClass: "d-block", attrs: { for: "channelApiKey" } },
                [_vm._v(" Channel Api key ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.channelApiKey,
                    expression: "channelApiKey",
                  },
                ],
                staticClass: "input",
                attrs: {
                  id: "channelApiKey",
                  type: "text",
                  placeholder: "Ingrese Channel Api Key",
                },
                domProps: { value: _vm.channelApiKey },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.channelApiKey = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "button",
                {
                  staticClass: "fulfillment-blue fulfillment-button",
                  attrs: { id: "reload" },
                  on: {
                    click: function ($event) {
                      return _vm.reload()
                    },
                  },
                },
                [_vm._v(" Recargar ")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "config-caontainer" }, [
          _c("div", { staticClass: "row title" }, [_vm._v("Párametros CICUE")]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                { staticClass: "d-block", attrs: { for: "clientEmail" } },
                [_vm._v(" Client Email ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.clientEmail,
                    expression: "clientEmail",
                  },
                ],
                staticClass: "input",
                attrs: {
                  id: "clientEmail",
                  type: "text",
                  placeholder: "Ingrese email de cliente",
                },
                domProps: { value: _vm.clientEmail },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.clientEmail = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                { staticClass: "d-block", attrs: { for: "timeoutCi" } },
                [_vm._v(" Timeout CI ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.timeoutCi,
                    expression: "timeoutCi",
                  },
                ],
                staticClass: "input",
                attrs: { id: "timeoutCi", type: "number", placeholder: "0" },
                domProps: { value: _vm.timeoutCi },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.timeoutCi = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                { staticClass: "d-block", attrs: { for: "timeoutCue" } },
                [_vm._v(" Timeout CUE ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.timeoutCue,
                    expression: "timeoutCue",
                  },
                ],
                staticClass: "input",
                attrs: { id: "timeoutCue", type: "number", placeholder: "0" },
                domProps: { value: _vm.timeoutCue },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.timeoutCue = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col" }, [
              _vm._v(" Strict Name Validation "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.strictNameValidation,
                    expression: "strictNameValidation",
                  },
                ],
                attrs: { id: "strictNameValidation", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.strictNameValidation)
                    ? _vm._i(_vm.strictNameValidation, null) > -1
                    : _vm.strictNameValidation,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.strictNameValidation,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.strictNameValidation = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.strictNameValidation = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.strictNameValidation = $$c
                    }
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "code d-m-block" }, [
          _c("div", { staticClass: "output-container code-container" }, [
            _c(
              "a",
              {
                staticClass: "copy-btn",
                class: { copied: _vm.tooltip },
                attrs: { "data-tooltip": "texto copiado" },
                on: { click: _vm.copyText },
              },
              [_vm._v("Copiar")]
            ),
            _c("code", [_vm._v(_vm._s(_vm.codeScript))]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.authResponse,
                  expression: "authResponse",
                },
              ],
              staticClass: "output-container code-container",
            },
            [_c("code", [_vm._v(_vm._s(_vm.authResponse))])]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.response,
                  expression: "response",
                },
              ],
              staticClass: "output-container code-container",
            },
            [_c("code", [_vm._v(_vm._s(_vm.response))])]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "result" }, [
      _c("h3", [_vm._v("Resultado")]),
      _c(
        "div",
        { staticClass: "widget", class: { "widget-mobile": _vm.isAndroid } },
        [
          _vm.isDevServer && _vm.isLoaded
            ? _c("Previred", {
                attrs: {
                  rut: _vm.rut,
                  "executive-rut": _vm.executiveRut,
                  "time-limit-mode": _vm.timeLimitMode,
                  "dummy-mode": _vm.dummyMode,
                  "auth-method": _vm.authMethod,
                  "kong-token": _vm.usingKongToken ? _vm.kongToken : undefined,
                  environment: _vm.environment,
                  "client-id": _vm.usingKongClientCredentials
                    ? _vm.kongClientIdField
                    : undefined,
                  "client-secret": _vm.usingKongClientCredentials
                    ? _vm.kongClientSecretField
                    : undefined,
                  "channel-key": _vm.channelApiKey,
                  "client-email": _vm.clientEmail,
                  "timeout-ci": _vm.timeoutCi,
                  "timeout-cue": _vm.timeoutCue,
                  "strict-name-validation": _vm.strictNameValidation,
                },
                on: {
                  auth: _vm.checkAuthResponse,
                  previred: _vm.checkResponse,
                },
              })
            : _vm._e(),
          !_vm.isDevServer && _vm.isLoaded
            ? _c("fulfillment-previred-widget", {
                attrs: {
                  rut: _vm.rut,
                  "executive-rut": _vm.executiveRut,
                  "time-limit-mode": _vm.timeLimitMode,
                  "dummy-mode": _vm.dummyMode,
                  "auth-method": _vm.authMethod,
                  "kong-token": _vm.usingKongToken ? _vm.kongToken : undefined,
                  environment: _vm.environment,
                  "client-id": _vm.usingKongClientCredentials
                    ? _vm.kongClientIdField
                    : undefined,
                  "client-secret": _vm.usingKongClientCredentials
                    ? _vm.kongClientSecretField
                    : undefined,
                  "channel-key": _vm.channelApiKey,
                  "client-email": _vm.clientEmail,
                  "timeout-ci": _vm.timeoutCi,
                  "timeout-cue": _vm.timeoutCue,
                  "strict-name-validation": _vm.strictNameValidation,
                },
                on: {
                  auth: function ($event) {
                    return _vm.checkAuthResponse($event.detail[0])
                  },
                  previred: function ($event) {
                    return _vm.checkResponse($event.detail[0])
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "d-block", attrs: { for: "rut" } }, [
      _vm._v(" Rut "),
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "d-block", attrs: { for: "kongToken" } },
      [
        _vm._v(" Kong Token "),
        _c("span", { staticClass: "red" }, [_vm._v("*")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "d-block", attrs: { for: "kongClientIdField" } },
      [
        _vm._v(" Kong client id "),
        _c("span", { staticClass: "red" }, [_vm._v("*")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "d-block", attrs: { for: "kongClientSecretField" } },
      [
        _vm._v(" Kong client secret "),
        _c("span", { staticClass: "red" }, [_vm._v("*")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "environment" } }, [
      _c("b", [_vm._v("Ambiente")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }