import { detect } from "detect-browser";

function checkOS(os) {
  const browser = detect();
  return browser.os.includes(os);
}

export function isAndroid() {
  return !checkOS("Windows");
}
