import { render, staticRenderFns } from "./Previred.vue?vue&type=template&id=67c94eeb"
import script from "./Previred.vue?vue&type=script&lang=js"
export * from "./Previred.vue?vue&type=script&lang=js"
import style0 from "fulfillment-cuprum-auth-widget/dist/fulfillment-cuprum-auth-widget.css?vue&type=style&index=0&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fulfillment-previred-widget/fulfillment-previred-widget/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67c94eeb')) {
      api.createRecord('67c94eeb', component.options)
    } else {
      api.reload('67c94eeb', component.options)
    }
    module.hot.accept("./Previred.vue?vue&type=template&id=67c94eeb", function () {
      api.rerender('67c94eeb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Previred.vue"
export default component.exports