<template>
  <div class="main">
    <h1>Previred Widget Test App</h1>
    <div class="d-flex w-100">
      <div class="form d-m-block">
        <div class="config-caontainer">
          <div class="row title">Párametros comunes</div>
          <div class="row">
            <div class="col">
              <label for="rut" class="d-block">
                Rut
                <span class="red">*</span>
              </label>
              <input
                id="rut"
                type="text"
                :value="rut"
                @input="rut = $event.target.value.toUpperCase()"
                placeholder="Ingrese su rut"
                class="input"
              />
            </div>
            <div class="col">
              <label for="name" class="d-block">Rut ejecutivo</label>
              <input
                id="executiveRut"
                type="text"
                :value="executiveRut"
                @input="executiveRut = $event.target.value.toUpperCase()"
                placeholder="Ingrese rut ejecutivo"
                class="input"
              />
            </div>
            <div class="col">
              <label for="authMethod" class="d-block">Método de autenticación</label>
              <select v-model="authMethod" id="authMethod" class="w-100 input">
                <option value="ACEPTA">ACEPTA - Validación de huella</option>
                <option value="EQUIFAX">EQUIFAX - Preguntas y Respuestas</option>
                <option value="SINACOFI">SINACOFI - Preguntas y Respuestas</option>
                <option value="REMOTE_ASSISTED">HIBRIDO - Remoto Asistido</option>
                <option value="REMOTE_ASSISTED_FACIAL">HIBRIDO - Remoto Facial</option>
                <option value="CICUE">HIBRIDO - Remoto CICUE</option>
                <option value="FACIAL_RECOGNITION">TOC - Reconocimiento Facial</option>
              </select>
            </div>
            <div class="col">
              <label for="timeLimitMode" class="d-block">Limite de tiempo Desafío</label>
              <select v-model="timeLimitMode" id="timeLimitMode" class="w-100 input">
                <option value="PER_ANSWER">Por pregunta</option>
                <option value="PER_QUIZ">Por quiz</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="method" class="d-block">Modo Kong</label>
              <select v-model="kongMethod" id="kongMethod" class="w-100 input">
                <option :value="kongMethods.CLIENT_CREDENTIALS" selected>Client Credentials</option>
                <option :value="kongMethods.TOKEN">Bearer Token</option>
              </select>
            </div>
            <div class="col" v-if="kongMethod === kongMethods.TOKEN">
              <label for="kongToken" class="d-block">
                Kong Token
                <span class="red">*</span>
              </label>
              <input
                id="kongToken"
                class="input"
                v-model="kongToken"
                type="text"
                placeholder="Ingrese su Kong Token"
              />
            </div>
            <div class="col" v-if="kongMethod === kongMethods.CLIENT_CREDENTIALS">
              <label for="kongClientIdField" class="d-block">
                Kong client id
                <span class="red">*</span>
              </label>
              <input
                id="kongClientIdField"
                class="input"
                v-model="kongClientIdField"
                type="text"
                placeholder="Ingrese su Kong Client Id"
              />
            </div>
            <div class="col" v-if="kongMethod === kongMethods.CLIENT_CREDENTIALS">
              <label for="kongClientSecretField" class="d-block">
                Kong client secret
                <span class="red">*</span>
              </label>
              <input
                id="kongClientSecretField"
                class="input"
                v-model="kongClientSecretField"
                type="text"
                placeholder="Ingrese Kong Secret"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              Modo dummy
              <input v-model="dummyMode" id="dummyMode" type="checkbox" />
            </div>
            <div class="col">
              <label for="environment"><b>Ambiente</b></label>
              <select v-model="environment" id="environment" class="w-100 input">
                <option v-for="env in allowedEnvironments" :key="env" :value="env">{{
                  env
                }}</option>
              </select>
            </div>
            <div class="col">
              <label for="channelApiKey" class="d-block">
                Channel Api key
              </label>
              <input
                id="channelApiKey"
                class="input"
                v-model="channelApiKey"
                type="text"
                placeholder="Ingrese Channel Api Key"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="fulfillment-blue fulfillment-button" id="reload" @click="reload()">
                Recargar
              </button>
            </div>
          </div>
        </div>
        <div class="config-caontainer">
          <div class="row title">Párametros CICUE</div>
          <div class="row">
            <div class="col">
                <label for="clientEmail" class="d-block">
                  Client Email
                </label>
                <input
                  id="clientEmail"
                  class="input"
                  v-model="clientEmail"
                  type="text"
                  placeholder="Ingrese email de cliente"
                />
              </div>
              <div class="col">
                <label for="timeoutCi" class="d-block">
                  Timeout CI
                </label>
                <input
                  id="timeoutCi"
                  class="input"
                  v-model="timeoutCi"
                  type="number"
                  placeholder="0"
                />
              </div>
              <div class="col">
                <label for="timeoutCue" class="d-block">
                  Timeout CUE
                </label>
                <input
                  id="timeoutCue"
                  class="input"
                  v-model="timeoutCue"
                  type="number"
                  placeholder="0"
                />
              </div>
              <div class="col">
                Strict Name Validation
                <input v-model="strictNameValidation" id="strictNameValidation" type="checkbox" />
              </div>
          </div>
        </div>
        <div class="code d-m-block">
          <div class="output-container code-container">
            <a
              class="copy-btn"
              v-bind:class="{ copied: tooltip }"
              @click="copyText"
              data-tooltip="texto copiado"
              >Copiar</a
            >
            <code>{{ codeScript }}</code>
          </div>
          <div v-show="authResponse" class="output-container code-container">
            <code>{{ authResponse }}</code>
          </div>
          <div v-show="response" class="output-container code-container">
            <code>{{ response }}</code>
          </div>
        </div>
      </div>
    </div>
    <div class="result">
      <h3>Resultado</h3>
      <div class="widget" v-bind:class="{ 'widget-mobile': isAndroid }">
        <Previred
          v-if="isDevServer && isLoaded"
          :rut="rut"
          :executive-rut="executiveRut"
          :time-limit-mode="timeLimitMode"
          :dummy-mode="dummyMode"
          :auth-method="authMethod"
          :kong-token="usingKongToken ? kongToken : undefined"
          :environment="environment"
          :client-id="usingKongClientCredentials ? kongClientIdField : undefined"
          :client-secret="usingKongClientCredentials ? kongClientSecretField : undefined"
          :channel-key="channelApiKey"
          :client-email="clientEmail"
          :timeout-ci="timeoutCi"
          :timeout-cue="timeoutCue"
          :strict-name-validation="strictNameValidation"
          @auth="checkAuthResponse"
          @previred="checkResponse"
        ></Previred>
        <fulfillment-previred-widget
          v-if="!isDevServer && isLoaded"
          :rut="rut"
          :executive-rut="executiveRut"
          :time-limit-mode="timeLimitMode"
          :dummy-mode="dummyMode"
          :auth-method="authMethod"
          :kong-token="usingKongToken ? kongToken : undefined"
          :environment="environment"
          :client-id="usingKongClientCredentials ? kongClientIdField : undefined"
          :client-secret="usingKongClientCredentials ? kongClientSecretField : undefined"
          :channel-key="channelApiKey"
          :client-email="clientEmail"
          :timeout-ci="timeoutCi"
          :timeout-cue="timeoutCue"
          :strict-name-validation="strictNameValidation"
          @auth="checkAuthResponse($event.detail[0])"
          @previred="checkResponse($event.detail[0])"
        ></fulfillment-previred-widget>
      </div>
    </div>
  </div>
</template>
 
<script>
import Previred, { AUTH_METHODS } from "@/components/Previred";
import Config from "@/services/config";
import * as web from "@/services/web";
 
const kongMethods = {
  CLIENT_CREDENTIALS: "clientCredentials",
  TOKEN: "token"
};
 
export default {
  name: "App",
  components: {
    Previred
  },
  data() {
    return {
      name: "",
      rut: "17115692-0",
      executiveRut: undefined,
      tooltip: false,
      authMethod: AUTH_METHODS.REMOTE_ASSISTED,
      timeLimitMode: "PER_ANSWER",
      dummyMode: false,
      authResponse: "",
      response: "",
      kongMethods: kongMethods,
      kongMethod: kongMethods.CLIENT_CREDENTIALS,
      kongToken: undefined,
      kongClientIdField: undefined,
      kongClientSecretField: undefined,
      isLoaded: true,
      environment: Config.buildEnvironment(),
      allowedEnvironments: Config.allowedEnvironments(),
      isDevServer: process.env.VUE_APP_IS_DEV_SERVER === "true",
      channelApiKey: "",
      clientEmail: "",
      timeoutCi: undefined,
      timeoutCue : undefined,
      strictNameValidation: false
    };
  },
  computed: {
    getKT(){
      const config = new Config(this.environment)
      let xhr = new XMLHttpRequest();
      xhr.open("POST", config.kongUrl("CUPRUM"), true);
      xhr.setRequestHeader("Content-Type", "application/json")
      xhr.onreadystatechange = () => {
        try {
          const token = xhr.response
          this.kongMethod = this.kongMethods.TOKEN
          this.kongToken = JSON.parse(token).access_token
        } catch (error) {console.log(error)}
      }
      xhr.send(JSON.stringify({
          "client_id": this.kongClientIdField,
          "client_secret": this.kongClientSecretField,
          "grant_type": "client_credentials",
        }))
      return true
    },
    usingKongToken() {
      return this.kongMethod === this.kongMethods.TOKEN;
    },
    usingKongClientCredentials() {
      return this.kongMethod === this.kongMethods.CLIENT_CREDENTIALS;
    },
    codeScript: function() {
      const getAttribute = (condition, attributeName, attributeValue) => 
        condition ? `:${attributeName}="${attributeValue}" ` : "";

    const attributes = [
      getAttribute(this.rut && this.rut !== "", "rut", this.rut),
      getAttribute(this.executiveRut && this.executiveRut !== "", "executive-rut", this.executiveRut),
      getAttribute(this.dummyMode, "dummy-mode", this.dummyMode),
      getAttribute(this.authMethod && this.authMethod !== "", "auth-method", this.authMethod),
      getAttribute(this.usingKongToken && this.kongToken, "kong-token", this.kongToken),
      getAttribute(this.usingKongClientCredentials && this.kongClientIdField, "client-id", this.kongClientIdField),
      getAttribute(this.usingKongClientCredentials && this.kongClientSecretField, "client-secret", this.kongClientSecretField),
      getAttribute(this.channelApiKey, "channel-key", this.channelApiKey),
      getAttribute(this.clientEmail && this.authMethod === AUTH_METHODS.CICUE, "client-email", this.clientEmail),
      getAttribute(this.timeoutCi && this.authMethod === AUTH_METHODS.CICUE, "timeout-ci", this.timeoutCi),
      getAttribute(this.timeoutCue && this.authMethod === AUTH_METHODS.CICUE, "timeout-cue", this.timeoutCue),
      getAttribute(this.strictNameValidation && this.authMethod === AUTH_METHODS.CICUE, "strict-name-validation", this.strictNameValidation)
    ].join('');

  return `<Previred ${attributes.trim()}></Previred>`;
}
  },
  methods: {
    reload() {
      this.isLoaded = false;
      setTimeout(() => {
        this.isLoaded = true;
        this.response = "";
      }, 250);
    },
    checkAuthResponse(resp) {
      this.authResponse = JSON.stringify(resp);
    },
    checkResponse(resp) {
      this.response = JSON.stringify(resp);
    },
    copyText() {
      const el = document.createElement("textarea");
      el.value = this.codeScript;
      el.setAttribute("readonly", "");
      el.style = { position: "absolute", left: "-9999px" };
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.tooltip = true;
      setTimeout(() => (this.tooltip = false), 1000);
    }
  },
  created() {
    this.isAndroid = web.isAndroid();
  },
  watch: {
    kongClientIdField(value){
      this.kongClientIdField = value
      if (this.kongClientSecretField !== undefined) {
        return this.getKT
      }
    },
    kongClientSecretField(value){
      this.kongClientSecretField = value
      if (this.kongClientIdField !== undefined) {
        return this.getKT
      }
    },
  }
};
</script>
 
<style scoped>
.main {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
    Verdana, sans-serif;
}
 
.widget-mobile {
  width: 80% !important;
}
 
.widget {
  border: 3px dashed #e6e6e6;
  border-radius: 5px;
}
 
.result {
  clear: both;
  margin: auto;
  max-width: 630px;
}
 
.config-caontainer {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  margin: 10px;
}
 
.w-100 {
  width: 100%;
}
.d-flex {
  display: flex;
}
.red {
  color: red;
}
.d-block {
  display: block;
}
 
.row {
  display: flex;
  vertical-align: middle;
  align-items: center;
  align-content: stretch;
  flex-wrap: wrap;
}
 
.title {
  background-color: #e6e6e6;
  padding: 8px;
  border-bottom: 1px solid #c6c6c6;
  cursor: pointer;
}
 
.col {
  flex: auto;
  padding: 8px;
  position: relative;
  flex-wrap: wrap;
}
 
.help {
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: #919191;
}
 
.input {
  border: none;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  background-color: transparent;
  line-height: 18px;
  padding: 5px 0px;
}
 
.separator {
  min-height: 29px;
  padding-top: 5px;
  box-sizing: border-box;
}
 
.output-container {
  display: block;
  padding: 15px;
  margin: 8px;
  word-break: break-word;
}
 
.copy-btn {
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
  color: #868e96;
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}
 
.copy-btn:hover {
  color: #0091da;
}
 
.copy-btn.copied::after {
  content: attr(data-tooltip);
  transition: all 0.18s ease-out 0.18s;
  font-size: 12px;
  background: rgba(33, 37, 41, 0.9);
  border-radius: 4px;
  color: #fff;
  left: -50%;
  top: 100%;
  padding: 0.5em 1em;
  position: absolute;
  white-space: nowrap;
  z-index: 10;
}
 
.code-container {
  position: relative;
  background-color: #f5f6f7;
  padding: 20px;
  border-radius: 5px;
  font-size: 14px;
}
 
.code-container:hover .copy-btn {
  display: inline;
}
 
.form {
  flex: 3;
}
.code {
  flex: 2;
}
 
@media screen and (max-width: 992px) {
  .code-container {
    padding-right: 60px;
  }
  .copy-btn {
    display: inline;
  }
  .d-flex {
    flex-direction: column;
  }
  .d-m-block {
    display: block;
  }
}
 
.fulfillment-button {
  min-height: 44px;
  min-width: 149px;
  border-radius: 22px;
  background-color: #e6e6e6;
  border: none;
  font-weight: bold;
  color: #757575;
  font-size: 16px;
  cursor: pointer;
}
.fulfillment-blue {
  background-color: #0091da;
  color: white;
  outline: none;
}
</style>